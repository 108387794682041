<template>
  <div>
    <div style="height: auto" class="common_liucheng">
      <span style="margin-left: 50px; font-weight: bold">我的咨询</span>
      <div class="neirong">
        <div class="nei_rong_box">
          <Table
            border
            :columns="columns12"
            :data="data7"
            :disabled-hover="true"
          >
            <template slot-scope="{ row }" slot="name">
              <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, list }" slot="action">
              <Button
                type="primary"
                size="small"
                style="margin-right: 5px; color: rgb(54, 194, 207)"
                @click="show(row)"
                >查看</Button
              >
            </template>
          </Table>
          <Page
            :total="total"
            show-elevator
            style="margin-top: 50px; float: right"
            @on-change="changePage"
          />
        </div>
      </div>
    </div>
    <!--咨询-->
    <Modal
      id="ReplayModal"
      v-model="ReplayModal"
      title="咨询详情"
      width="500"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div style="font-weight: bold">咨询内容：</div>
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <span>{{ consultContent }}</span>
          </div>
        </div>

        <div
          id="replay"
          style="
            background-color: rgb(245, 246, 250);
            position: relative;
            padding: 20px;
            margin-bottom: 50px;
          "
        >
          <div style="font-weight: bold; margin-left: 20px">回复内容：</div>
          <div
            style="
              width: 90%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <span>{{ replayContent }}</span>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      id="NoReplayModal"
      v-model="NoReplayModal"
      title="咨询详情"
      width="500"
      :footer-hide="footHide"
    >
      <div style="margin-bottom: 50px">
        <div style="padding: 20px">
          <div style="font-weight: bold">咨询内容：</div>
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <span>{{ consultContent }}</span>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getConsultingList } from "../../../plugins/api/EnterpriseManagementCenter";

export default {
  //请求数据,params为路由的参数
  // asyncData({ params }) {
  //   return getData('').then(res => {
  //     return { data7: res.data.title }
  //   })
  // },
  name: "EnterpriseConsultingManagement",
  mounted() {
    this.getConsultList();
  },
  data() {
    return {
      replayContent: "",
      consultContent: "",
      footHide: false,
      ReplayModal: false,
      NoReplayModal: false,
      total: 0,
      data7: [],  // table数据
      columns12: [
        {
          title: "咨询时间",
          key: "createdTime",
          align: "center",
        },
        {
          title: "咨询内容",
          key: "reviewContent",
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  display: '-webkit-box',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                },
                domProps: {
                  title: params.row.reviewContent
                }
              }, params.row.reviewContent)
            ])
          }
        },
        {
          title: "咨询类型",
          key: "shopName",
          align: "center",
        },
        {
          title: "咨询状态",
          key: "reviewState",
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
    };
  },
  methods: {
    // 分页
    async changePage(index) {
      let params = {
        pageSize: 10,
        pageNum: index,
      };
      const json = await getConsultingList(params);
      if (json.code === 0) {
        this.data7 = json.result.list;
      }
    },

    // 得到列表
    async getConsultList() {
      let params = {
        pageSize: 10,
        pageNum: 1,
      };
      const json = await getConsultingList(params);
      if (json.code === 0) {
        this.data7 = json.result.list;
        this.data7.forEach((item) => {
          item.createdTime = item.createdTime.split(" ")[0]
        })
        this.total = json.result.total;
      }
      // this.$parent.changeHeight();
    },

    // 显示回复的modal
    show(row) {
      if (row.reviewState === "已回复") {
        this.ReplayModal = true;
        this.replayContent = row.replyContent;
        this.consultContent = row.reviewContent;
      } else {
        this.NoReplayModal = true;
        this.consultContent = row.reviewContent;
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
}
/deep/ .ivu-modal-footer {
  display: none;
}
/deep/ .ivu-modal-header-inner {
  text-align: center;
  background-color: rgb(245, 246, 250);
}

/deep/ .ivu-modal-header {
  background-color: rgb(245, 246, 250);
}
/deep/ td {
  border-right: none !important;
}
.neirong {
  margin-left: 5px;
  .nei_rong_box {
    height: auto;
    padding-bottom: 100px;
    width: 95%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  p {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}

.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}


/deep/ .ivu-table-tbody tr:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1)!important;
}
.common_liucheng {
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid;
  border-color: #f5f5f5;
  border-bottom: none;
  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
  }
}
</style>
